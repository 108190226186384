import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    rebus: [
        {
            pic: require('../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №1',
                videoQuestion: require('../assets/video/rus-1_start.mp4'),
                videoAnswer: require('../assets/video/rus-1_finish.mp4'),
                answer: 'АСПИРИН',
            },
            tat: {
                title: 'Ребус №1',
                videoQuestion: require('../assets/video/tat-1_start.mp4'),
                videoAnswer: require('../assets/video/tat-1_finish.mp4'),
                answer: 'АСПИРИН',
            },
        },
        {
            pic: require('../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №2',
                videoQuestion: require('../assets/video/rus-2_start.mp4'),
                videoAnswer: require('../assets/video/rus-2_finish.mp4'),
                answer: 'БРАТЬЯ КРЕСТОВНИКОВЫ',
            },
            tat: {
                title: 'Ребус №2',
                videoQuestion: require('../assets/video/tat-2_start.mp4'),
                videoAnswer: require('../assets/video/tat-2_finish.mp4'),
                answer: 'САБЫН ЗАВОДЫ',
            },
        },
        {
            pic: require('../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №3',
                videoQuestion: require('../assets/video/rus-3_start.mp4'),
                videoAnswer: require('../assets/video/rus-3_finish.mp4'),
                answer: 'ГАЛИАСКАР КАМАЛ',
            },
            tat: {
                title: 'Ребус №3',
                videoQuestion: require('../assets/video/tat-3_start.mp4'),
                videoAnswer: require('../assets/video/tat-3_finish.mp4'),
                answer: 'ГАЛИӘСГАР КАМАЛ',
            },
        },
        {
            pic: require('../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №4',
                videoQuestion: require('../assets/video/rus-4_start.mp4'),
                videoAnswer: require('../assets/video/rus-4_finish.mp4'),
                answer: 'ГЕРАЛЬДИКА',
            },
            tat: {
                title: 'Ребус №4',
                videoQuestion: require('../assets/video/tat-4_start.mp4'),
                videoAnswer: require('../assets/video/tat-4_finish.mp4'),
                answer: 'ГЕРАЛЬДИКА',
            },
        },
        {
            pic: require('../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №5',
                videoQuestion: require('../assets/video/rus-5_start.mp4'),
                videoAnswer: require('../assets/video/rus-5_finish.mp4'),
                answer: 'РЕСПУБЛИКА ТАТАРСТАН',
            },
            tat: {
                title: 'Ребус №5',
                videoQuestion: require('../assets/video/tat-5_start.mp4'),
                videoAnswer: require('../assets/video/tat-5_finish.mp4'),
                answer: 'ТАТАРСТАН РЕСПУБЛИКАСЫ',
            },
        },
        {
            pic: require('../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №6',
                videoQuestion: require('../assets/video/rus-6_start.mp4'),
                videoAnswer: require('../assets/video/rus-6_finish.mp4'),
                answer: 'СЕЙФСИТИ',
            },
            tat: {
                title: 'Ребус №6',
                videoQuestion: require('../assets/video/tat-6_start.mp4'),
                videoAnswer: require('../assets/video/tat-6_finish.mp4'),
                answer: 'СЕЙФСИТИ',
            },
        },
        {
            pic: require('../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №7',
                videoQuestion: require('../assets/video/rus-7_start.mp4'),
                videoAnswer: require('../assets/video/rus-7_finish.mp4'),
                answer: 'ТЕАТРАЛЬНАЯ ПЛОЩАДЬ',
            },
            tat: {
                title: 'Ребус №7',
                videoQuestion: require('../assets/video/tat-7_start.mp4'),
                videoAnswer: require('../assets/video/tat-7_finish.mp4'),
                answer: 'ТЕАТР МӘЙДАНЫ',
            },
        },
        {
            pic: require('../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №8',
                videoQuestion: require('../assets/video/rus-8_start.mp4'),
                videoAnswer: require('../assets/video/rus-8_finish.mp4'),
                answer: 'ЧЕРТЁЖ',
            },
            tat: {
                title: 'Ребус №8',
                videoQuestion: require('../assets/video/tat-8_start.mp4'),
                videoAnswer: require('../assets/video/tat-8_finish.mp4'),
                answer: 'СЫЗЫМ',
            },
        },
    ],
}

export const rebusSlice = createSlice({
    name: 'rebus',
    initialState,
    reducers: {
        setRebus: (state, action) => {
            console.log('action', action.payload)
            state.rebus = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setRebus } = rebusSlice.actions

export default rebusSlice.reducer
