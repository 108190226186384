import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    language: 'rus',
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            console.log('action', action.payload)
            state.language = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeLanguage } = languageSlice.actions

export default languageSlice.reducer
