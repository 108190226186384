import React, {useEffect, useState} from 'react';
import '../../styles.scss'
import topBackground from '../../assets/b.png'
import bottomBackground from '../../assets/nbb.png'
import { useSelector, useDispatch } from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {setRebus} from "../../store/rebusSlice";
import {useMediaQuery} from "react-responsive";




const Catalog = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1124px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const currentLanguage = useSelector((state) => state.language.language)
    const URL_PREFIX = 'http://ovz39.kunail919.m2oon.vps.myjino.ru/'

    const headerText = {
        rus: `
Во время своих приключений в архиве Блокнотику и Печати приходится разгадывать много разных загадок. Попробуй и ты им в этом помочь – разгадай ребусы!`,
        tat: `Архивта маҗаралы биремнәр үтәгән вакытта, Блокнотчык һәм Мөһергә төрле-төрле табышмакларга җавап табарга туры килә. Син дә аларга булыша аласың – әлеге ребусларны чишеп кара!`
    }
    const headerHi = {
        rus: 'Добрый день, дорогой друг!',
        tat: 'Хәерле көн, кадерле дустым!'
    }

    const items = [
        {
            pic: require('../../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №1',
                videoQuestion: require('../../assets/video/rus-1_start.mp4'),
                videoAnswer: require('../../assets/video/rus-1_finish.mp4'),
                answer: 'тест',
            },
            tat: {
                title: 'Rebus №1',
                videoQuestion: require('../../assets/video/tat-1_start.mp4'),
                videoAnswer: require('../../assets/video/tat-1_finish.mp4'),
                answer: 'test',
            },
        },
        {
            pic: require('../../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №2',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'тест',
            },
            tat: {
                title: 'Rebus №2',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'test',
            },
        },
        {
            pic: require('../../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №3',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'тест',
            },
            tat: {
                title: 'Rebus №3',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'test',
            },
        },
        {
            pic: require('../../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №4',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'тест',
            },
            tat: {
                title: 'Rebus №4',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'test',
            },
        },
        {
            pic: require('../../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №5',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'тест',
            },
            tat: {
                title: 'Rebus №5',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'test',
            },
        },
        {
            pic: require('../../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №6',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'тест',
            },
            tat: {
                title: 'Rebus №6',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'test',
            },
        },
        {
            pic: require('../../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №7',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'тест',
            },
            tat: {
                title: 'Rebus №7',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'test',
            },
        },
        {
            pic: require('../../assets/cardTitle.png'),
            rus: {
                title: 'Ребус №8',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'тест',
            },
            tat: {
                title: 'Rebus №8',
                videoQuestion: '',
                videoAnswer: '',
                answer: 'test',
            },
        },
    ]

    const [currentRebus, setCurrentRebus] = useState(null)

    return (
        <>
            <div className={'catalog-header column-div justify-end'} style={{}}>
                <div className={'mobile column-div'} style={{width: '98%', position: 'relative', marginInline: "auto"}}>
                        <p style={{marginInline: 10, marginTop: 70, marginBottom: 0}} className={'catalog-header-text'}><b>{headerHi[currentLanguage]}</b></p>
                        <p style={{marginInline: 10, marginTop: 0}}  className={'catalog-header-text'}>{headerText[currentLanguage]}</p>
                        <div className={'row-div'} style={{flexWrap: 'wrap', width: '90%', marginInline: 'auto', gap: 20}}>
                            {items.map((item, idx)=>(
                                <div onClick={()=>{ navigate('/module/'+idx)}} style={{width: 145, height: 148, cursor: 'pointer'}} className={'justify-center card-div'}>
                                    <div className={'rebus-card'}>
                                        <p className={' rebus-card-text'} >№{idx+1}</p>
                                    </div>
                                    <p className={'rebus-card-badge'}>Ребус</p>
                                </div>
                            ))}
                        </div>
                </div>


                <div style={{height: 325, maxWidth: 700, flexWrap: 'wrap', gap: 35, marginTop: 50}} className={'row-div desktop'}>
                    {items.map((item, idx)=>(
                        <div onClick={()=>{ navigate('/module/'+idx)}} style={{width: 145, height: 148, cursor: 'pointer'}} className={'justify-center card-div'}>
                            <div className={'rebus-card'}>
                                <p className={' rebus-card-text'} >№{idx+1}</p>
                            </div>
                            <p className={'rebus-card-badge'}>Ребус</p>
                        </div>
                    ))}
                </div>
                <div className={'row-div justify-center align-start desktop'} style={{backgroundImage: `url(${topBackground})`, width: 1212, height: 606, marginTop: 0, backgroundSize: 'contain', zIndex: 9, position: 'relative', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', marginBottom: -100}}>
                    <div style={{width: 200, height: 300, marginTop: 30}}>
                        <img src={require('../../assets/leftP.png')} height={300}/>
                    </div>
                    <div style={{width: 570, position: 'relative'}} className={'desktop'}>
                        <p style={{marginInline: 10, marginTop: 70, marginBottom: 0}} className={'catalog-header-text'}><b>{headerHi[currentLanguage]}</b></p>
                        <p style={{marginInline: 10, marginTop: 0}}  className={'catalog-header-text'}>{headerText[currentLanguage]}</p>
                    </div>
                    <div style={{width: 200, height: 226, marginTop: 100}}>
                        <img src={require('../../assets/rightP.png')} height={225}/>
                    </div>
                    {isBigScreen && (
                        <div style={{width: '100%', position: 'fixed', bottom: 0}}>
                            <p className={'copyright-desktop'}>ООО “Балачак”, 2022. Все права защищены</p>
                        </div>
                    )}
                </div>

                {isTabletOrMobile && (
                    <div className={'row-div justify-center align-center'} style={{width: '96%', marginInline: '2%'}}>
                        <p className={'copyright'}>ООО “Балачак”, 2022. <br/>Все права защищены</p>
                    </div>
                )}

            </div>
        </>
    )
};

export default Catalog;
