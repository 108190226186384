import React, {useState} from 'react';
import {Link, Outlet} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { changeLanguage } from './store/languageSlice'



const Layout = (props) => {
    const dispatch = useDispatch()
    const title = {
        rus: 'Интерактивные ребусы',
        tat: 'TAT Интерактивные ребусы'
    }
    const currentLanguage = useSelector((state) => state.language.language)

    function changeLanguageInStore() {
        if (currentLanguage==='rus') {
            dispatch(changeLanguage('tat'))
        }
        if (currentLanguage==='tat') {
            dispatch(changeLanguage('rus'))
        }
    }
    return (
        <div style={{width: '100%'}}>
            <div className={'row-div header align-center'}>
                <a href={'http://исталек.рф'} className={'row-div align-center desktop'} style={{marginRight: '10%'}}>
                    <img src={require('./assets/rLogo.png')} style={{width: 263, objectFit: 'contain'}}></img>
                </a>
                {/*<div className={'title-container'}>*/}
                {currentLanguage==='rus' && (
                    <img width={400} src={require('./assets/top.png')}  className={'top-banner'}/>
                )}
                {currentLanguage==='tat' && (
                    <img width={400} src={require('./assets/plashkaTat.png')}  className={'top-banner'}/>
                )}
                {/*</div>*/}
                <div className={'row-div justify-end mr-0  menu-text text__white'} style={{width: '10%', cursor: 'pointer'}} onClick={changeLanguageInStore}>
                    {currentLanguage==='rus' && (
                        <img src={require('./assets/rFlag.png')} width={65} height={50}/>
                    )}
                    {currentLanguage==='tat' && (
                        <img src={require('./assets/tFlag.png')} width={65} height={50}/>
                    )}
                    {/* <Link to={'/'} className={'mr-9 text__white'}>ПРОЕКТ ТУРЫНДА</Link>
                    <Link to={'/'} className={'text__white'}>КОНТАКТЛАР</Link> */}
                </div>

            </div>
            {/*<div style={{width: '100%', height: 3, background: '#FFFFFF'}}></div>*/}
            <Outlet />
        </div>
    );
};

export default Layout;
