import logo from './logo.svg';
import './App.css';
import Catalog from "./screens/Catalog/Catalog";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./Layout";
import Module from "./screens/Module/Module";
import {Provider} from "react-redux";
import { store } from './store/store'
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <div className="App">
        <Provider store={store}>
        <BrowserRouter>
            <div className={'container-div'}>
                <div className={'inner-container column-div'} style={{backgroundColor: 'rgba(0, 65, 227, 0)'}}>
                    <Routes>
                        <Route path="/module/:idx" element={<Module />} />
                        <Route path="/" element={<Layout />}>
                            <Route path="/" element={<Catalog />} />
                        </Route>
                    </Routes>
                </div>
            </div>

        </BrowserRouter>
        </Provider>
    </div>
  );
}

export default App;
