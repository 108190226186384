import { configureStore } from '@reduxjs/toolkit'
import {languageSlice} from "./languageSlice";
import {rebusSlice} from "./rebusSlice";

export const store = configureStore({
    reducer: {
        language: languageSlice.reducer,
        rebus: rebusSlice.reducer
    },
})
