import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {changeLanguage} from "../../store/languageSlice";
import {check, DefaultPlayer as Video} from 'react-html5video';
import { Player } from 'video-react';
import 'react-html5video/dist/styles.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {useMediaQuery} from "react-responsive";


const Module = (props) => {
    const rebuses = useSelector((state)=>state.rebus.rebus)
    const [rebus, setRebus] = useState(null)
    const videoRef = React.useRef(null);
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const currentLanguage = useSelector((state) => state.language.language)
    const [modalShow, setModalShow] = React.useState(false);
    const [answer, setAnswer] = React.useState(null)
    const [currentVideo, setCurrentVideo] = React.useState(null)
    const [isPlaying, setIsPlaying] = React.useState(true)
    const [answerStatus, setAnswerStatus] = React.useState('none')
    const [currentRebus, setCurrentRebus] = React.useState(null)

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1124px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    useEffect(()=>{
        console.log(rebuses[params.idx])
        setCurrentRebus(params.idx)
        setRebus(rebuses[params.idx])
        setCurrentVideo(rebuses[params.idx][currentLanguage].videoQuestion)
    }, [])

    useEffect(()=>{
        if (currentRebus && rebuses && rebuses[parseInt(currentRebus)] && currentLanguage && rebuses[parseInt(currentRebus)][currentLanguage] ) {
            setCurrentVideo(rebuses[parseInt(currentRebus)][currentLanguage].videoQuestion)
        }

    }, [currentLanguage])

    // useEffect(()=>{
    //     console.log(rebuses[params.idx])
    //     setCurrentRebus(params.idx)
    //     setRebus(rebuses[params.idx])
    //     setCurrentVideo(rebuses[params.idx][currentLanguage].videoQuestion)
    // }, [rebuses])

    function nextRebus() {
        if (parseInt(currentRebus)+1===rebuses.length) {
            navigate('/')
            return
        }
        setRebus(rebuses[parseInt(currentRebus)+1])
        setCurrentVideo(rebuses[parseInt(currentRebus)+1][currentLanguage].videoQuestion)
        videoRef?.current?.play();
        setAnswer(null)
        setAnswerStatus('none')
        setModalShow(false)
        setIsPlaying(true)
    }

    function checkAnswer() {
        console.log(answer)
        if (answer && answer.toLowerCase()===rebus[currentLanguage].answer.toLowerCase()) {
            setAnswerStatus('correct')

        }
        if (answer && answer.toLowerCase()!==rebus[currentLanguage].answer.toLowerCase()) {
            setAnswerStatus('incorrect')
            setTimeout(()=>{
                setAnswerStatus('none')
            },1500)
        }

        //setModalShow(false)
        //setCurrentVideo(rebus[currentLanguage].videoAnswer)
        //setIsPlaying(true)
    }

    function closeModal() {
        setModalShow(false)
        setCurrentVideo(rebus[currentLanguage].videoAnswer)
        setIsPlaying(true)
    }

    useEffect(()=>{
        console.log(currentVideo)
    }, [currentVideo])

    function changeLanguageInStore() {
        if (currentLanguage==='rus') {
            dispatch(changeLanguage('tat'))
        }
        if (currentLanguage==='tat') {
            dispatch(changeLanguage('rus'))
        }
    }

    return (
       <div className={'module'}>
           {rebus && isBigScreen && (
               <div className={'inner-container'}>
                   <div className={'row-div justify-space-between align-center pt-3 pb-3 desktop'} >
                       <div onClick={()=>{navigate('/')}} className={'row-div align-center pointer'}>
                           <img src={require('../../assets/menu.png')} style={{width: 112, objectFit: 'contain'}}></img>
                       </div>
                       <div className={'module-menu-title-container'}>
                           {currentLanguage==='rus' && (
                               <p style={{marginTop: 2}}>Интерактивные ребусы</p>
                           )}
                           {currentLanguage==='tat' && (
                               <p style={{marginTop: 3}}>Интерактив ребуслар</p>
                           )}
                       </div>
                       <div className={'row-div justify-end mr-0  menu-text text__white'} style={{width: '10%', cursor: 'pointer'}} onClick={changeLanguageInStore}>
                           {currentLanguage==='rus' && (
                               <img src={require('../../assets/rFlag.png')} width={65} height={50}/>
                           )}
                           {currentLanguage==='tat' && (
                               <img src={require('../../assets/tFlag.png')} width={65} height={50}/>
                           )}

                       </div>
                   </div>

                   <div className={'row-div justify-space-between align-center pt-3 pb-3 mobile'} >
                       <div onClick={()=>{navigate('/')}} className={'row-div align-center pointer'} style={{width: '50%'}}>
                           <img src={require('../../assets/menu.png')} style={{width: 112, objectFit: 'contain'}}></img>
                       </div>
                       <div className={'row-div justify-end mr-0  menu-text text__white'} style={{width: '10%', cursor: 'pointer'}} onClick={changeLanguageInStore}>
                           {currentLanguage==='rus' && (
                               <img src={require('../../assets/rFlag.png')} width={65} height={50}/>
                           )}
                           {currentLanguage==='tat' && (
                               <img src={require('../../assets/tFlag.png')} width={65} height={50}/>
                           )}

                       </div>
                   </div>
                   <div className={'module-video-container column-div align-center'}>
                       <div className={'video-title'} style={{marginLeft: 150, marginRight: 150, textAlign: 'left', marginTop: 30, width: '75%'}}>{rebus[currentLanguage].title}</div>
                       <div style={{marginInline: 150, marginTop: 30, marginBottom: 30, position: 'relative'}}>
                           {currentVideo && (
                               // <Video autoPlay
                               //        controls={[]}
                               //        onCanPlayThrough={() => {
                               //            // Do stuff
                               //        }}>
                               //     <source src={currentVideo} type="video/mp4" />
                               // </Video>
                               // <Player playsInline style={{width: 500}}>
                               //     <source width={600} height={600} src={currentVideo} />
                               // </Player>
                               <video className={'pointer video-player'} onClick={()=>{
                                   if (isPlaying) {
                                       videoRef.current.pause();                                 setIsPlaying(false)
                                   }
                                   if (!isPlaying) {
                                       videoRef.current.play();                                 setIsPlaying(true)
                                   }
                               }} ref={videoRef} autoPlay={true} src={currentVideo} width={window.innerWidth>600 ? 900 : ''}>

                               </video>

                           )}
                           {!isPlaying && (
                               <div className={'pointer play-btn'} style={{marginTop: 0, zIndex: 999, position: "absolute", top: '40%', left: '45%'}} onClick={()=>{
                                   videoRef.current.play();
                                   setIsPlaying(true)
                               }}>
                                   <img width={100} src={require('../../assets/playB.png')}/>
                               </div>
                           )}
                       </div>

                       {currentLanguage==='rus' && answerStatus!=='correct' && (
                           <div onClick={()=>{setModalShow(true); videoRef?.current?.pause(); setIsPlaying(false)}} className={'answer-button row-div align-center justify-center pointer'}>
                               Ответить
                           </div>
                       )}
                       {currentLanguage==='rus' && answerStatus==='correct' && (
                           <div onClick={nextRebus} className={'answer-button row-div align-center justify-center pointer'}>
                               Следующий
                           </div>
                       )}
                       {currentLanguage==='tat' &&  answerStatus!=='correct' && (
                           <div onClick={()=>{setModalShow(true); videoRef?.current?.pause(); setIsPlaying(false)}} className={'answer-button row-div align-center justify-center pointer'}>
                               Җавап бирергә
                           </div>
                       )}
                       {currentLanguage==='tat' &&  answerStatus==='correct' && (
                           <div onClick={nextRebus} className={'answer-button row-div align-center justify-center pointer'}>
                               КИЛӘСЕ
                           </div>
                       )}
                   </div>

               </div>
           )}
           {rebus && isTabletOrMobile && (
                <div >
               <div className={'row-div justify-space-between align-center pt-3 pb-3 desktop'} >
                   <div onClick={()=>{navigate('/')}} className={'row-div align-center pointer'}>
                       <img src={require('../../assets/menu.png')} style={{width: 112, objectFit: 'contain'}}></img>
                   </div>
                   <div className={'module-menu-title-container'}>
                       {currentLanguage==='rus' && (
                           <p style={{marginTop: 2}}>Интерактивные ребусы</p>
                       )}
                       {currentLanguage==='tat' && (
                           <p style={{marginTop: 3}}>Интерактив ребуслар</p>
                       )}
                   </div>
                   <div className={'row-div justify-end mr-0  menu-text text__white'} style={{width: '10%', cursor: 'pointer'}} onClick={changeLanguageInStore}>
                       {currentLanguage==='rus' && (
                           <img src={require('../../assets/rFlag.png')} width={65} height={50}/>
                       )}
                       {currentLanguage==='tat' && (
                           <img src={require('../../assets/tFlag.png')} width={65} height={50}/>
                       )}

                   </div>
               </div>

               <div className={'row-div justify-space-between align-center pt-3 pb-3 mobile'} >
                   <div onClick={()=>{navigate('/')}} className={'row-div align-center pointer'} style={{width: '50%'}}>
                       <img src={require('../../assets/menu.png')} style={{width: 112, objectFit: 'contain'}}></img>
                   </div>
                   <div className={'row-div justify-end mr-0  menu-text text__white'} style={{width: '10%', cursor: 'pointer'}} onClick={changeLanguageInStore}>
                       {currentLanguage==='rus' && (
                           <img src={require('../../assets/rFlag.png')} width={65} height={50}/>
                       )}
                       {currentLanguage==='tat' && (
                           <img src={require('../../assets/tFlag.png')} width={65} height={50}/>
                       )}

                   </div>
               </div>
               <div className={'module-video-container column-div align-center'}>
                   <div className={'video-title'} style={{marginLeft: 150, marginRight: 150, textAlign: 'left', marginTop: 30, width: '75%'}}>{rebus[currentLanguage].title}</div>
                   <div style={{marginInline: 150, marginTop: 30, marginBottom: 30, position: 'relative'}}>
                       {currentVideo && (
                           // <Video autoPlay
                           //        controls={[]}
                           //        onCanPlayThrough={() => {
                           //            // Do stuff
                           //        }}>
                           //     <source src={currentVideo} type="video/mp4" />
                           // </Video>
                           // <Player playsInline style={{width: 500}}>
                           //     <source width={600} height={600} src={currentVideo} />
                           // </Player>
                           <video className={'pointer video-player'} onClick={()=>{
                               if (isPlaying) {
                                   videoRef.current.pause();                                 setIsPlaying(false)
                               }
                               if (!isPlaying) {
                                   videoRef.current.play();                                 setIsPlaying(true)
                               }
                           }} ref={videoRef} autoPlay={true} src={currentVideo} width={window.innerWidth>600 ? 900 : ''}>

                           </video>

                       )}
                       {!isPlaying && (
                           <div className={'pointer play-btn'} style={{marginTop: 0, zIndex: 999, position: "absolute", top: '20%', left: '40%', transform: 'scale(0.5)'}} onClick={()=>{
                               videoRef.current.play();
                               setIsPlaying(true)
                           }}>
                               <img width={100} src={require('../../assets/playB.png')}/>
                           </div>
                       )}
                   </div>

                   {currentLanguage==='rus' && answerStatus!=='correct' && (
                       <div onClick={()=>{setModalShow(true); videoRef?.current?.pause(); setIsPlaying(false)}} className={'answer-button row-div align-center justify-center pointer'}>
                           Ответить
                       </div>
                   )}
                   {currentLanguage==='rus' && answerStatus==='correct' && (
                       <div onClick={nextRebus} className={'answer-button row-div align-center justify-center pointer'}>
                           Следующий
                       </div>
                   )}
                   {currentLanguage==='tat' &&  answerStatus!=='correct' && (
                       <div onClick={()=>{setModalShow(true); videoRef?.current?.pause(); setIsPlaying(false)}} className={'answer-button row-div align-center justify-center pointer'}>
                           Җавап бирергә
                       </div>
                   )}
                   {currentLanguage==='tat' &&  answerStatus==='correct' && (
                       <div onClick={nextRebus} className={'answer-button row-div align-center justify-center pointer'}>
                           КИЛӘСЕ
                       </div>
                   )}
               </div>
                    <div className={'row-div justify-center align-center'} style={{position:"fixed", bottom: 35, height: 70, left: 0, right: 0, width: '96%', marginInline: '2%'}}>
                        <p className={'copyright'}>ООО “Балачак”, 2022. <br/>Все права защищены</p>
                    </div>

                </div>
           )}
           <Modal
               show={modalShow}
               onHide={() => setModalShow(false)}
               size="lg"
               style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}

               backdropClassName={'modal-backdrop'}
               aria-labelledby="contained-modal-title-vcenter"
               centered
           >

               <Modal.Body>
                   {currentLanguage==='rus' && (
                       <span className={'modal-title'}>Впиши слово</span>
                   )}
                   {currentLanguage==='tat' && (
                       <span className={'modal-title'}>Сүзне языгыз</span>
                   )}
                   <Form.Control
                       onChange={(e)=> {
                           setAnswer(e.target.value)
                           setAnswerStatus('none')
                       }}
                       className={'answer-input'}
                       autoFocus
                       style={answerStatus==='correct' ? {backgroundColor: '#00F571'} : answerStatus==='incorrect' ? {backgroundColor: '#FF7171'} : {backgroundColor: '#fff'}}
                   />
                   {currentLanguage==='rus' && answerStatus && answerStatus!=='correct' && (
                       <>
                           <div className={'check-button row-div justify-center align-center pointer'} onClick={checkAnswer}>Проверить</div>
                           <div className={'show-again-text pointer'} onClick={()=>{
                               setModalShow(false); setCurrentVideo(rebus[currentLanguage].videoQuestion); setIsPlaying(true); videoRef?.current?.play();
                           }}>ПОСМОТРЕТЬ РОЛИК ЕЩЕ РАЗ</div>
                       </>
                   )}
                   {currentLanguage==='tat' && answerStatus && answerStatus!=='correct' && (
                       <>
                           <div className={'check-button row-div justify-center align-center pointer'} onClick={checkAnswer}>Тикшерергә</div>
                           <div className={'show-again-text pointer'} onClick={()=>{
                               setModalShow(false); setCurrentVideo(rebus[currentLanguage].videoQuestion); setIsPlaying(true); videoRef?.current?.play();
                           }}>РОЛИКНЫ ТАГЫН БЕР ТАПКЫР КАРАРГА</div>
                       </>
                   )}
                   {currentLanguage==='rus' && answerStatus && answerStatus==='correct' && (
                       <>
                           <div className={'check-button row-div justify-center align-center pointer'} onClick={closeModal}>Закрыть</div>

                       </>
                   )}
                   {currentLanguage==='tat' && answerStatus && answerStatus==='correct' && (
                       <>
                           <div className={'check-button row-div justify-center align-center pointer'} onClick={closeModal}>Ябарга</div>

                       </>
                   )}
               </Modal.Body>

           </Modal>
       </div>
   )
};

export default Module;
